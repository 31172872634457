import { platformActions } from '../platformActions';
import { track } from '../lib/reporting/actions';
import _ from 'lodash';
import { getDispatch } from '../configureMiddleware';

const trackComments = (event, params = {}) => {
  const Mixpanel = platformActions.mixpanel;
  const dispatch = getDispatch();
  const { projectId, parentId, comment, size } = params;

  let trackProps = {};
  let trackTitle = event;

  if (projectId) {
    trackProps['projectId'] = projectId;
  }
  if (parentId) {
    trackProps['parentId'] = parentId;
  }

  switch (event) {
    case COMMENTS_EVENTS.CREATE_NEW_COMMENT: {
      trackTitle = 'comment';
      _.assign(trackProps, comment, { ownerId: comment?.owner?.id });
      break;
    }
    case COMMENTS_EVENTS.RETRY_COMMENTS_UPLOAD: {
      trackProps.size = size;
      break;
    }
  }

  dispatch(track(event, trackProps));
};

export default trackComments;

export const COMMENTS_EVENTS = {
  START_COMMENTS_LISTENER: 'START_COMMENTS_LISTENER',
  END_COMMENTS_LISTENER: 'END_COMMENTS_LISTENER',
  FETCH_COMMENTS: 'FETCH_COMMENTS',

  CREATE_NEW_COMMENT: 'CREATE_NEW_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  UPLOAD_NEW_POST_COMMENT: 'UPLOAD_NEW_POST_COMMENT',
  UPLOAD_NEW_CHECKLIST_STATUS_COMMENT: 'UPLOAD_NEW_CHECKLIST_STATUS_COMMENT',
  RETRY_COMMENTS_UPLOAD: 'RETRY_COMMENTS_UPLOAD',

  // POST_COMMENTS_LOADING: 'POST_COMMENTS_LOADING',

  // UPDATE_IMAGE_STARTED: 'UPDATE_IMAGE_STARTED', // >?
  // GET_NEW_COMMENT_ID: 'GET_NEW_COMMENT_ID', // ?

  // GET_LOCAL_COMMENT: 'GET_LOCAL_COMMENT',
};
